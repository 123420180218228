import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import PrintIcon from '@mui/icons-material/Print';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {
  formatDateTask,
  formatDescrTask,
  formatDescrVolume,
  getDefaultAlertProps,
  getSelectedLng,
  getTooltipErrors,
  getViewType,
  replaceQuoat,
  roundValue,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  DEVICE_STATUS,
  IP_LOCAL_NAME,
  LANGUAGE_RUS,
  MOBILE_VIEW,
  PAD_VIEW,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  ROUND_BATTERY,
  ROUND_KOMP,
  ROUND_PRES,
  ROUND_TEMP,
  ROUND_VOLUME_ALL,
  START_PAGE_SIZE_DEVICES,
  STATE,
} from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import {
  DEVICES_ARCHIVE_ROUTE,
  DEVICES_CHART_ROUTE,
  DEVICES_INFO_ROUTE,
  DEVICES_SETUP_ROUTE,
} from '../../constant/routes';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetDevices } from '../../service/device/apiGetDevices';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { DeviceStageEnum } from '../../enum/DeviceStageEnum';
import { apiDeleteSetup } from '../../service/setup/apiDeleteSetup';
import { apiDeleteUpdate } from '../../service/service/apiDeleteUpdate';

export const DevicesDiv = () => {
  const navigate = useNavigate();
  const { search } = useParams();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [deviceDtos, setDeviceDtos] = useState<DeviceListDto[]>([]);

  const title = localStorage.getItem(DEVICE_STATUS) || '';
  const state = localStorage.getItem(STATE) || '';

  const getDevices = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetDevices(state, search);
    if (apiResult.isSuccess) {
      setDeviceDtos(apiResult.data.devices);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const deleteTask = async (dto: DeviceListDto) => {
    const apiResult: IApiResult = await apiDeleteSetup(dto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const deleteUpdate = async (dto: DeviceListDto) => {
    const apiResult: IApiResult = await apiDeleteUpdate(dto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            title={`${t('nowErrors')}:\n${getTooltipErrors(
              row.row.events
            )}\n\n${t('memmoryErrors')}:\n${getTooltipErrors(
              row.row.eventsMemmory
            )}`}
            size="small"
          >
            <WarningIcon
              fontSize="small"
              sx={{
                color:
                  row.row.events !== '0' || row.row.eventsMemmory !== '0'
                    ? '#ef5350'
                    : '#bdbdbd',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'deviceNum',
      headerName: t('deviceNum'),
      renderHeader: () => <strong>{t('deviceNum')}</strong>,
      minWidth: getViewType() === PC_VIEW ? 240 : 150,
      editable: false,
      renderCell: (row) => (
        <Stack direction="row" alignItems="center" width="100%">
          <Stack width="100%">
            <a
              href={`${DEVICES_INFO_ROUTE}/${row.row.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.row.deviceNum}
            </a>
          </Stack>
          {getViewType() === PC_VIEW && (
            <Stack direction="row" spacing={0}>
              <IconButton
                size="small"
                title={t('tooltipInfo')}
                onClick={() => navigate(`${DEVICES_INFO_ROUTE}/${row.row.id}`)}
              >
                <PermDeviceInformationIcon
                  fontSize="small"
                  sx={{ color: '#29b6f6' }}
                />
              </IconButton>
              <IconButton
                size="small"
                title={t('tooltipPrint')}
                onClick={() =>
                  navigate(`${DEVICES_ARCHIVE_ROUTE}/${row.row.id}`)
                }
              >
                <PrintIcon fontSize="small" sx={{ color: '#ab47bc' }} />
              </IconButton>
              <IconButton
                size="small"
                title={t('tooltipChart')}
                onClick={() => navigate(`${DEVICES_CHART_ROUTE}/${row.row.id}`)}
              >
                <ShowChartIcon fontSize="small" sx={{ color: '#ffca28' }} />
              </IconButton>
              <IconButton
                size="small"
                title={t('tooltipSetup')}
                onClick={() => navigate(`${DEVICES_SETUP_ROUTE}/${row.row.id}`)}
              >
                <SettingsIcon fontSize="small" sx={{ color: '#9ccc65' }} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ),
    },
    {
      field: 'holder',
      headerName: t('holder'),
      renderHeader: () => <strong>{t('holder')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => replaceQuoat(row.row.holder, 0),
    },
    {
      field: 'grupName',
      headerName: t('group'),
      renderHeader: () => <strong>{t('group')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) =>
        row.row.grupName === '' ? t('notChoose') : row.row.grupName,
    },
    {
      field: 'location',
      headerName: t('address'),
      renderHeader: () => <strong>{t('address')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      hide:
        getViewType() !== PC_VIEW ||
        parseInt(state, 10) === DeviceStageEnum.TASK,
      renderCell: (row) => row.row.location,
    },
    {
      field: 'taskLogUser',
      headerName: t('taskLogUser'),
      renderHeader: () => <strong>{t('taskLogUser')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      hide:
        getViewType() !== PC_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.TASK,
      renderCell: (row) => (
        <div title={formatDescrTask(row.row.taskDescr)}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton>
              <InfoIcon
                fontSize="small"
                sx={{
                  color: '#2196f3',
                }}
              />
            </IconButton>
            {formatDateTask(row.row.taskLogUser)}
          </Stack>
        </div>
      ),
    },
    {
      field: 'dateSert',
      headerName: t('sertDate'),
      renderHeader: () => <strong>{t('sertDate')}</strong>,
      minWidth: 200,
      editable: false,
      hide:
        getViewType() === MOBILE_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.SERTIFICATE,
      renderCell: (row) => (
        <div style={{ color: row.row.isSert === 1 ? '#ef5350' : '#4caf50' }}>
          {row.row.dateSert}
        </div>
      ),
    },
    {
      field: 'timeLeft',
      headerName: t('timeLeft'),
      renderHeader: () => <strong>{t('timeLeft')}</strong>,
      minWidth: 200,
      editable: false,
      hide:
        getViewType() === MOBILE_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.BATTERY,
      renderCell: (row) => roundValue(row.row.timeLeft, ROUND_BATTERY, true),
    },
    {
      field: 'volumeSuSum',
      headerName: t('headerListSumVolSt'),
      renderHeader: () => <strong>{t('headerListSumVolSt')}</strong>,
      minWidth: 150,
      editable: false,
      hide:
        getViewType() === MOBILE_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.ALL,
      renderCell: (row) => (
        <div
          title={
            row.row.volumePeriodDescr !== ''
              ? formatDescrVolume(row.row.volumePeriodDescr)
              : undefined
          }
        >
          {roundValue(row.row.volumeSuSum, ROUND_VOLUME_ALL, true)}
        </div>
      ),
    },
    {
      field: 'temp',
      headerName: t('headerListTemp'),
      renderHeader: () => <strong>{t('headerListTemp')}</strong>,
      minWidth: 100,
      editable: false,
      hide:
        getViewType() !== PC_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.ALL,
      renderCell: (row) => roundValue(row.row.temp, ROUND_TEMP, true),
    },
    {
      field: 'pres',
      headerName: t('headerListPres'),
      renderHeader: () => <strong>{t('headerListPres')}</strong>,
      minWidth: 150,
      editable: false,
      hide:
        getViewType() !== PC_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.ALL,
      renderCell: (row) => roundValue(row.row.pres, ROUND_PRES, true),
    },
    {
      field: 'komp',
      headerName: t('headerListComp'),
      renderHeader: () => <strong>{t('headerListComp')}</strong>,
      minWidth: 110,
      editable: false,
      hide:
        getViewType() !== PC_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.ALL,
      renderCell: (row) => roundValue(row.row.komp, ROUND_KOMP, true),
    },
    {
      field: 'events',
      headerName: t('nowErrors'),
      renderHeader: () => <strong>{t('nowErrors')}</strong>,
      minWidth: 150,
      editable: false,
      hide:
        getViewType() === MOBILE_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.WARNING,
      renderCell: (row) => (
        <div title={getTooltipErrors(row.row.events)}>
          {row.row.events !== '0' ? row.row.events : t('noError')}
        </div>
      ),
    },
    {
      field: 'eventsMemmory',
      headerName: t('memmoryErrors'),
      renderHeader: () => <strong>{t('memmoryErrors')}</strong>,
      minWidth: 150,
      editable: false,
      hide:
        localStorage.getItem(IP_LOCAL_NAME) === '91.245.227.7' ||
        getViewType() === MOBILE_VIEW ||
        parseInt(state, 10) !== DeviceStageEnum.WARNING,
      renderCell: (row) => (
        <div title={getTooltipErrors(row.row.eventsMemmory)}>
          {row.row.eventsMemmory !== '0' ? row.row.eventsMemmory : t('noError')}
        </div>
      ),
    },
    {
      field: 'dateCon',
      headerName: t('connect'),
      renderHeader: () => <strong>{t('connect')}</strong>,
      minWidth: 165,
      editable: false,
      hide:
        getViewType() === MOBILE_VIEW ||
        (getViewType() === PAD_VIEW &&
          parseInt(state, 10) === DeviceStageEnum.WARNING),
      renderCell: (row) => (
        <div style={{ color: row.row.isConnect === 1 ? '#ef5350' : undefined }}>
          {unixToLocal(row.row.dateCon)}
        </div>
      ),
    },
    {
      field: 'id2',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      hide: parseInt(state, 10) !== DeviceStageEnum.TASK,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipDeleteTask')}
            onClick={() => deleteTask(row.row)}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'id4',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      hide: parseInt(state, 10) !== DeviceStageEnum.UPDATE,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipDeleteUpdate')}
            onClick={() => deleteUpdate(row.row)}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsMobile: GridColDef[] = [
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton title={getTooltipErrors(row.row.events)} size="small">
            <WarningIcon
              fontSize="small"
              sx={{ color: row.row.events !== '0' ? '#ef5350' : '#bdbdbd' }}
            />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'deviceNum',
      headerName: t('info'),
      flex: 1,
      renderHeader: () => <strong>{t('info')}</strong>,
      editable: false,
      renderCell: (row) => (
        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={1}
          width="100%"
        >
          <Stack direction="row" width="100%" spacing={2}>
            <a
              href={`${DEVICES_INFO_ROUTE}/${row.row.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.row.deviceNum}
            </a>
          </Stack>
          <Stack width="100%">
            {t('holder')}: {replaceQuoat(row.row.holder, 0)}
          </Stack>
          <Stack width="100%">
            {t('headerListSumVolSt')}:{' '}
            {roundValue(row.row.volumeSuSum, ROUND_VOLUME_ALL, true)}
          </Stack>
          <Stack width="100%">
            {t('connect')}: {unixToLocal(row.row.dateCon)}
          </Stack>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv title={title} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '1px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={
                  getViewType() !== MOBILE_VIEW ? columns : columnsMobile
                }
                rows={deviceDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
};
