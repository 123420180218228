import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import {
  checkUpdateSetupTask,
  formatNumberToBool,
  formatResetBatteryToBool,
  getActionDescription,
  getArrayMeasPeriod,
  getDefaultAlertProps,
  getDispOnArray,
  getDispOnDescription,
  getDispRotateArray,
  getDispRotateDescription,
  getEnableFlowMin,
  getEnableFlowMinDescription,
  getIdArray,
  getMagnetFlowActionArray,
  getNameArray,
  getRootLevel,
  getShowVolumeArray,
  getShowVolumeDescription,
  getTempActionArray,
  getValveArray,
  getValveDescription,
  getValvePolArray,
  getValvePolDescription,
  getValveTypeArray,
  getValveTypeDescription,
  getViewType,
  replaceQuoat,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  LEVEL_READ,
  MAIN_COLOR_STRING,
  PC_VIEW,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DeviceSetupDto } from '../../dto/DeviceSetupDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetSetup } from '../../service/setup/apiGetSetup';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { MyTextFiledTask } from '../UI/MyTextFieldTask';
import { MyAutoCompleteTask } from '../UI/MuAutoCompleteTask';
import { apiUpdateSetup } from '../../service/setup/apiUpdateSetup';
import { DeviceSetupTaskDto } from '../../dto/DeviceSetupTaskDto';
import { MyCheckBoxTask } from '../UI/MyCheckBoxTask';
import { apiPrintSetup } from '../../service/setup/apiPrintSetup';

export const DeviceSetupDiv = () => {
  const { id } = useParams();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const root = getRootLevel();
  const magnetFlowActions = getMagnetFlowActionArray();
  const tempActions = getTempActionArray();
  const dispOnArray = getDispOnArray();
  const dispRotateArray = getDispRotateArray();
  const showVolumeArray = getShowVolumeArray();
  const valveArray = getValveArray();
  const valveTypeArray = getValveTypeArray();
  const valvePolArray = getValvePolArray();
  const enableFlowMin = getEnableFlowMin();
  const measPeriod = getArrayMeasPeriod();
  const { t } = useTranslation();
  const [checkHelper] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [deviceSetupDto, setDeviceSetupDto] = useState<DeviceSetupDto>();

  const [deviceSetupTaskDto, setDeviceSetupTaskDto] =
    useState<DeviceSetupTaskDto>({
      id: -1,
      deviceNum: '',
      taskConstPres: '',
      taskTempMinLimit: '',
      taskTempMaxLimit: '',
      taskMinLevel: '',
      taskMinTempGprs: '',
      taskServerAddress: '',
      taskPeriodTempMeas: '',
      taskConstTempMin: '',
      taskConstTempMax: '',
      taskConstKomp: '',
      taskContrHour: '',
      taskContrDay: '',
      taskContrVal: '',
      taskLcdOn: '',
      taskLcdRotate: '',
      taskTOptionMin: '',
      taskTOptionMax: '',
      taskMagnetOption: '',
      taskDatetime: '',
      taskPassword1: '',
      taskPassword2: '',
      taskResetMagnet: '',
      taskResetBox: '',
      taskResetFlowMax: '',
      taskResetTempLimit: '',
      taskResetAdcd: '',
      taskResetCalibErr: '',
      taskResetBox2: '',
      taskResetBattery: '',
      taskShowVolumeRu: '',
      taskGetBalance: '',
      taskEnableFlowMin: '',
      taskResetMemmErr: '',
      taskResetFlowMinErr: '',
      taskEnableValve: '',
      taskValveType: '',
      taskValvePol: '',
    });

  const getSetup = async (idDevice: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetSetup(idDevice);
    if (apiResult.isSuccess) {
      setDeviceSetupDto(apiResult.data.setup);
      setDeviceSetupTaskDto(apiResult.data.setupTask);
      document.title = `${t('flowMeter')} № ${apiResult.data.setup.deviceNum}`;
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const updateSetup = async () => {
    const apiResult: IApiResult = await apiUpdateSetup(deviceSetupTaskDto);
    if (apiResult.isSuccess) {
      setIsEdit(false);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleUpdate = async () => {
    const errorValid = checkUpdateSetupTask(deviceSetupTaskDto);
    if (errorValid !== '') {
      setAlertProps({
        message: errorValid,
        severity: AlertSeverityEnum.info,
      });
      return;
    }
    await updateSetup();
  };

  const getArch = async (idDevice: string, name?: string) => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiPrintSetup(idDevice);
    if (apiResult.isSuccess) {
      FileDownload(apiResult.data, `Настройки_${name}`);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handlePdfSetup = async () => {
    await getArch(id ?? '', `${deviceSetupDto?.deviceNum}.pdf`);
  };

  useEffect(() => {
    getSetup(id ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv
            title={t('setups')}
            isFlowmeter
            deviceInfo={
              deviceSetupDto &&
              `${t('flowMeter')} № ${deviceSetupDto?.deviceNum} ${replaceQuoat(
                deviceSetupDto?.holder,
                0
              )}`
            }
          />
        </Stack>
        <Stack direction="column" width="90%" spacing={2}>
          <Stack
            direction={getViewType() === PC_VIEW ? 'column' : 'row'}
            spacing={2}
          >
            <Card
              style={{
                padding: '20px',
              }}
            >
              <Stack spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Stack
                    width="100%"
                    alignItems="center"
                    direction="row"
                    spacing={1}
                  >
                    {root !== LEVEL_READ && (
                      <Stack>
                        <IconButton
                          title={t('tooltipEdit')}
                          size="medium"
                          onClick={() => setIsEdit(!isEdit)}
                        >
                          <EditIcon fontSize="medium" sx={{ color: 'red' }} />
                        </IconButton>
                      </Stack>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      size="medium"
                      onClick={handlePdfSetup}
                      title={t('tooltipPdf')}
                      sx={{ color: 'red' }}
                    >
                      <PictureAsPdfIcon fontSize="medium" />
                    </IconButton>
                    <IconButton
                      disabled={!isEdit}
                      title={t('tooltipSave')}
                      size="medium"
                      onClick={handleUpdate}
                    >
                      <SaveIcon
                        fontSize="medium"
                        sx={{ color: isEdit ? MAIN_COLOR_STRING : 'gray' }}
                      />
                    </IconButton>
                  </Stack>
                </Stack>
                <Divider />
                <Stack padding="10px">
                  <Grid container spacing={2}>
                    <Grid item xs={getViewType() === PC_VIEW ? 4 : 12}>
                      <Stack alignItems="flex-start" spacing={1}>
                        <Typography
                          style={{
                            fontFamily: 'sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            color: '#000000',
                            letterSpacing: 1,
                            cursor: 'pointer',
                          }}
                          component="text"
                        >
                          {t('titleUpp')}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <MyTextFiledTask
                          value={deviceSetupDto?.constPres}
                          valueTask={deviceSetupTaskDto.taskConstPres}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskConstPres: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('constPres')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.constKomp}
                          valueTask={deviceSetupTaskDto.taskConstKomp}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskConstKomp: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('constKomp')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.constTempMin}
                          valueTask={deviceSetupTaskDto.taskConstTempMin}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskConstTempMin: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('constMinTemp')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.constTempMax}
                          valueTask={deviceSetupTaskDto.taskConstTempMax}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskConstTempMax: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('constMaxTemp')}
                          size="small"
                        />
                      </Stack>
                      <Stack
                        alignItems="flex-start"
                        paddingTop="10px"
                        spacing={1}
                      >
                        <Typography
                          style={{
                            fontFamily: 'sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            color: '#000000',
                            letterSpacing: 1,
                            cursor: 'pointer',
                          }}
                          component="text"
                        >
                          {t('titleControl')}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <MyTextFiledTask
                          value={deviceSetupDto?.tempMinLimit}
                          valueTask={deviceSetupTaskDto.taskTempMinLimit}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskTempMinLimit: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('minTempLimit')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.tempMaxLimit}
                          valueTask={deviceSetupTaskDto.taskTempMaxLimit}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskTempMaxLimit: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('maxTempLimit')}
                          size="small"
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.magnetOption !== undefined &&
                            deviceSetupDto?.magnetOption !== -1
                              ? getActionDescription(
                                  deviceSetupDto.magnetOption
                                )
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskMagnetOption !== undefined &&
                            parseInt(
                              deviceSetupTaskDto.taskMagnetOption,
                              10
                            ) !== -1
                              ? getActionDescription(
                                  parseInt(
                                    deviceSetupTaskDto.taskMagnetOption,
                                    10
                                  )
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskMagnetOption: magnetFlowActions
                                .indexOf(v)
                                .toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={magnetFlowActions}
                          size="small"
                          label={t('actionMagnet')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.tOptionMin !== undefined &&
                            deviceSetupDto?.tOptionMin !== -1
                              ? getActionDescription(deviceSetupDto?.tOptionMin)
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskTOptionMin !== undefined &&
                            parseInt(deviceSetupTaskDto.taskTOptionMin, 10) !==
                              -1
                              ? getActionDescription(
                                  parseInt(
                                    deviceSetupTaskDto.taskTOptionMin,
                                    10
                                  )
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskTOptionMin: tempActions.indexOf(v).toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={tempActions}
                          size="small"
                          label={t('actionMinTemp')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.tOptionMax !== undefined &&
                            deviceSetupDto?.tOptionMax !== -1
                              ? getActionDescription(deviceSetupDto?.tOptionMax)
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskTOptionMax !== undefined &&
                            parseInt(deviceSetupTaskDto.taskTOptionMax, 10) !==
                              -1
                              ? getActionDescription(
                                  parseInt(
                                    deviceSetupTaskDto.taskTOptionMax,
                                    10
                                  )
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskTOptionMax: tempActions.indexOf(v).toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={tempActions}
                          size="small"
                          label={t('actionMaxTemp')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.enableFlowMin !== undefined &&
                            deviceSetupDto?.enableFlowMin !== -1
                              ? getEnableFlowMinDescription(
                                  deviceSetupDto?.enableFlowMin
                                )
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskEnableFlowMin !==
                              undefined &&
                            parseInt(
                              deviceSetupTaskDto.taskEnableFlowMin,
                              10
                            ) !== -1
                              ? getEnableFlowMinDescription(
                                  parseInt(
                                    deviceSetupTaskDto.taskEnableFlowMin,
                                    10
                                  )
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskEnableFlowMin: enableFlowMin
                                .indexOf(v)
                                .toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={enableFlowMin}
                          size="small"
                          label={t('enableFlowMin')}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={getViewType() === PC_VIEW ? 4 : 12}>
                      <Stack alignItems="flex-start" spacing={1}>
                        <Typography
                          style={{
                            fontFamily: 'sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            color: '#000000',
                            letterSpacing: 1,
                            cursor: 'pointer',
                          }}
                          component="text"
                        >
                          {t('titleContr')}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <MyTextFiledTask
                          value={deviceSetupDto?.contrVal}
                          valueTask={deviceSetupTaskDto.taskContrVal}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskContrVal: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('contrVal')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.contrHour}
                          valueTask={deviceSetupTaskDto.taskContrHour}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskContrHour: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('contrHour')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.contrDay}
                          valueTask={deviceSetupTaskDto.taskContrDay}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskContrDay: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('contrDay')}
                          size="small"
                        />
                        <div style={{ height: '48px' }}> </div>
                      </Stack>

                      <Stack
                        alignItems="flex-start"
                        paddingTop="10px"
                        spacing={1}
                      >
                        <Typography
                          style={{
                            fontFamily: 'sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            color: '#000000',
                            letterSpacing: 1,
                            cursor: 'pointer',
                          }}
                          component="text"
                        >
                          {t('titleServer')}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        <MyTextFiledTask
                          value={deviceSetupDto?.serverAddress}
                          valueTask={deviceSetupTaskDto.taskServerAddress}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskServerAddress: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="text"
                          label={t('server')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.minLevel}
                          valueTask={deviceSetupTaskDto.taskMinLevel}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskMinLevel: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('minSign')}
                          size="small"
                        />
                        <MyTextFiledTask
                          value={deviceSetupDto?.minTempGprs}
                          valueTask={deviceSetupTaskDto.taskMinTempGprs}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskMinTempGprs: e.target.value,
                            })
                          }
                          error={checkHelper}
                          disabled={!isEdit}
                          type="number"
                          label={t('minGprsTemp')}
                          size="small"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={getViewType() === PC_VIEW ? 4 : 12}>
                      <Stack alignItems="flex-start" spacing={1}>
                        <Typography
                          style={{
                            fontFamily: 'sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            color: '#000000',
                            letterSpacing: 1,
                            cursor: 'pointer',
                          }}
                          component="text"
                        >
                          {t('titleSystem')}
                        </Typography>
                        <Divider sx={{ width: '100%' }} />
                        {false && (
                          <MyTextFiledTask
                            valueTask={deviceSetupTaskDto.taskPassword1}
                            onChange={(e) =>
                              setDeviceSetupTaskDto({
                                ...deviceSetupTaskDto,
                                taskPassword1: e.target.value,
                              })
                            }
                            error={checkHelper}
                            disabled={!isEdit}
                            type="number"
                            label={t('password1')}
                            size="small"
                          />
                        )}
                        {false && (
                          <MyTextFiledTask
                            valueTask={deviceSetupTaskDto.taskPassword2}
                            onChange={(e) =>
                              setDeviceSetupTaskDto({
                                ...deviceSetupTaskDto,
                                taskPassword2: e.target.value,
                              })
                            }
                            error={checkHelper}
                            disabled={!isEdit}
                            type="number"
                            label={t('password2')}
                            size="small"
                          />
                        )}
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.periodTempMeas !== undefined &&
                            deviceSetupDto?.periodTempMeas !== -1
                              ? getNameArray(measPeriod)[
                                  Object.values(getIdArray(measPeriod)).indexOf(
                                    deviceSetupDto.periodTempMeas
                                  )
                                ]
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskPeriodTempMeas !==
                              undefined &&
                            parseInt(
                              deviceSetupTaskDto.taskPeriodTempMeas,
                              10
                            ) !== -1
                              ? getNameArray(measPeriod)[
                                  Object.values(getIdArray(measPeriod)).indexOf(
                                    parseInt(
                                      deviceSetupTaskDto.taskPeriodTempMeas,
                                      10
                                    )
                                  )
                                ]
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskPeriodTempMeas:
                                getIdArray(measPeriod)[
                                  Object.values(
                                    getNameArray(measPeriod)
                                  ).indexOf(v)
                                ],
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={getNameArray(measPeriod)}
                          size="small"
                          label={t('tempMeasPeriod')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.lcdOn !== undefined &&
                            deviceSetupDto?.lcdOn !== -1
                              ? getDispOnDescription(deviceSetupDto?.lcdOn)
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskLcdOn !== undefined &&
                            parseInt(deviceSetupTaskDto.taskLcdOn, 10) !== -1
                              ? getDispOnDescription(
                                  parseInt(deviceSetupTaskDto.taskLcdOn, 10)
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskLcdOn: dispOnArray.indexOf(v).toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={dispOnArray}
                          size="small"
                          label={t('dispOn')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.lcdRotate !== undefined &&
                            deviceSetupDto?.lcdRotate !== -1
                              ? getDispRotateDescription(
                                  deviceSetupDto?.lcdRotate
                                )
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskLcdRotate !== undefined &&
                            parseInt(deviceSetupTaskDto.taskLcdRotate, 10) !==
                              -1
                              ? getDispRotateDescription(
                                  parseInt(deviceSetupTaskDto.taskLcdRotate, 10)
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskLcdRotate: dispRotateArray
                                .indexOf(v)
                                .toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={dispRotateArray}
                          size="small"
                          label={t('dispRotate')}
                        />

                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.showVolumeRu !== undefined &&
                            deviceSetupDto?.showVolumeRu !== -1
                              ? getShowVolumeDescription(
                                  deviceSetupDto?.showVolumeRu
                                )
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskShowVolumeRu !== undefined &&
                            parseInt(
                              deviceSetupTaskDto.taskShowVolumeRu,
                              10
                            ) !== -1
                              ? getShowVolumeDescription(
                                  parseInt(
                                    deviceSetupTaskDto.taskShowVolumeRu,
                                    10
                                  )
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskShowVolumeRu: showVolumeArray
                                .indexOf(v)
                                .toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={showVolumeArray}
                          size="small"
                          label={t('showVolumeRu')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.valveType !== undefined &&
                            deviceSetupDto?.valveType !== -1
                              ? getValveTypeDescription(
                                  deviceSetupDto?.valveType
                                )
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskValveType !== undefined &&
                            parseInt(deviceSetupTaskDto.taskValveType, 10) !==
                              -1
                              ? getValveTypeDescription(
                                  parseInt(deviceSetupTaskDto.taskValveType, 10)
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskValveType: valveTypeArray
                                .indexOf(v)
                                .toString(),
                            })
                          }
                          disabled
                          error={checkHelper}
                          arrayList={valveTypeArray}
                          size="small"
                          label={t('valveType')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.valvePol !== undefined &&
                            deviceSetupDto?.valvePol !== -1
                              ? getValvePolDescription(deviceSetupDto?.valvePol)
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskValvePol !== undefined &&
                            parseInt(deviceSetupTaskDto.taskValvePol, 10) !== -1
                              ? getValvePolDescription(
                                  parseInt(deviceSetupTaskDto.taskValvePol, 10)
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskValvePol: valvePolArray.indexOf(v).toString(),
                            })
                          }
                          disabled
                          error={checkHelper}
                          arrayList={valvePolArray}
                          size="small"
                          label={t('valvePol')}
                        />
                        <MyAutoCompleteTask
                          maxHeightDropList="200"
                          value={
                            deviceSetupDto?.enableValve !== undefined &&
                            deviceSetupDto?.enableValve !== -1
                              ? getValveDescription(deviceSetupDto?.enableValve)
                              : ''
                          }
                          valueTask={
                            deviceSetupTaskDto.taskEnableValve !== undefined &&
                            parseInt(deviceSetupTaskDto.taskEnableValve, 10) !==
                              -1
                              ? getValveDescription(
                                  parseInt(
                                    deviceSetupTaskDto.taskEnableValve,
                                    10
                                  ) - 2
                                )
                              : ''
                          }
                          onChange={(e, v) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskEnableValve: (
                                valveArray.indexOf(v) + 2
                              ).toString(),
                            })
                          }
                          disabled={!isEdit}
                          error={checkHelper}
                          arrayList={valveArray}
                          size="small"
                          label={t('enableValve')}
                        />
                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskDatetime}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskDatetime: e.target.checked ? 'true' : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('syncdatetime')}
                        />
                        <MyCheckBoxTask
                          valueTask={formatNumberToBool(
                            deviceSetupTaskDto.taskGetBalance
                          )}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskGetBalance: e.target.checked ? '1' : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('getBalance')}
                        />
                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskResetMagnet}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskResetMagnet: e.target.checked ? 'true' : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('resetMagnet')}
                        />
                        {false && (
                          <MyCheckBoxTask
                            valueTask={deviceSetupTaskDto.taskResetBox}
                            onChange={(e) =>
                              setDeviceSetupTaskDto({
                                ...deviceSetupTaskDto,
                                taskResetBox: e.target.checked ? 'true' : '',
                              })
                            }
                            disabled={!isEdit}
                            label={t('resetBox')}
                          />
                        )}
                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskResetFlowMax}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskResetFlowMax: e.target.checked ? 'true' : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('resetFlowMax')}
                        />
                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskResetTempLimit}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskResetTempLimit: e.target.checked
                                ? 'true'
                                : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('resetTempLimit')}
                        />

                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskResetAdcd}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskResetAdcd: e.target.checked ? 'true' : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('resetAdcd')}
                        />
                        {false && (
                          <MyCheckBoxTask
                            valueTask={deviceSetupTaskDto.taskResetCalibErr}
                            onChange={(e) =>
                              setDeviceSetupTaskDto({
                                ...deviceSetupTaskDto,
                                taskResetCalibErr: e.target.checked
                                  ? 'true'
                                  : '',
                              })
                            }
                            disabled={!isEdit}
                            label={t('resetCalibErr')}
                          />
                        )}
                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskResetBox2}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskResetBox2: e.target.checked ? 'true' : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('resetBox2')}
                        />
                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskResetMemmErr}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskResetMemmErr: e.target.checked ? 'true' : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('resetMemmory')}
                        />
                        <MyCheckBoxTask
                          valueTask={deviceSetupTaskDto.taskResetFlowMinErr}
                          onChange={(e) =>
                            setDeviceSetupTaskDto({
                              ...deviceSetupTaskDto,
                              taskResetFlowMinErr: e.target.checked
                                ? 'true'
                                : '',
                            })
                          }
                          disabled={!isEdit}
                          label={t('resetFlowMin')}
                        />
                        {false && (
                          <MyCheckBoxTask
                            valueTask={formatResetBatteryToBool(
                              deviceSetupTaskDto.taskResetBattery
                            )}
                            onChange={(e) =>
                              setDeviceSetupTaskDto({
                                ...deviceSetupTaskDto,
                                taskResetBattery: e.target.checked ? '100' : '',
                              })
                            }
                            disabled={!isEdit}
                            label={t('resetBattery')}
                          />
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
